<script>
  import PostInterpretationPrototype from '@/prototypes/PostInterpretationPrototype';

  export default {
    asyncData({store, route}) {
      let promisesArr = '';

      if (!route.query.copy) {
        store.dispatch('PostingNotifierStore/preselectSecretaryInterpretationStaticData');
      }
      if (store.getters['PostingNotifierStore/mainDataShouldBeFetched']) {
        promisesArr = [...promisesArr, store.dispatch('PostingNotifierStore/fetchMainData')];
      }
      // Filling the selectors for pricing templates
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.demanderTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'demander')];
      }
      if (store.state.TTAuthStore.godmode && Object.keys(store.state.PricingTemplatesStore.supplierTemplates).length === 0) {
        promisesArr = [...promisesArr, store.dispatch('PricingTemplatesStore/getTemplates', 'supplier')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    extends: PostInterpretationPrototype,
    computed: {
      mainLinesNote() {
        return this.$gettext('As an additional service, we offer secretarial assignments. What is a secretarial assignment? These are assignments where the interpreter is used to convey shorter messages directly to a non - Norwegian - speaking patient / client. There may be messages related to a doctor\'s appointment (fasting before a blood test, changed appointment, etc.), or to ensure that the patient has received a summons to the appointment. Name, phone number and message will be visible after assignment confirmation.');
      },
      mainLines() {
        return [{
          components: [{
            name: 'language-section',
            params: {
              langToText: this.$gettext('Language'),
              disabledFields: this.disabledImportantFields
            }
          }, {
            name: 'deadline-section',
            params: {
              disabledFields: this.disabledImportantFields,
              secretaryAssignment: true,
              showAssignmentHelper: true,
              context: 'interpretation'
            }
          }]
        }, {
          components: [{
            name: 'subject-section',
            condition: this.showCategoriesSection,
            params: {
              showAssignmentHelper: true
            }
          }]
        }, {
          components: [{
            name: 'warning-section',
            condition: true
          }]
        }, {
          components: [{
            name: 'secretary-section',
            params: {
              showAssignmentHelper: true
            }
          }]
        }];
      },
      footerTextParams() {
        return {
          dateTimes: [{
            startTime: this.deadline,
          }],
          languageId: this.languageTo
        };
      },
      summaryObj() {
        return {
          btnText: this.reviewModalBtnText,
          job: {
            languageFromId: this.languageFrom,
            languageToId: this.languageTo,
            alternativeLanguageToId: this.alternativeLanguageTo,
            sessionType: this.assignmentType,
            qualificationId: this.qualification,
            deadline: this.deadline,
            directInterpreterName: this.directInterpreterName,
            secretaryContactName: this.secretaryContactName,
            secretaryContactPhone: this.secretaryContactPhone,
            secretaryContactPhoneCode: this.secretaryContactPhoneCode,
            secretaryInfo: this.secretaryInfo,
            secretaryCallbackPhone: this.secretaryCallbackPhone,
            secretaryCallbackPhoneCode: this.secretaryCallbackPhoneCode,
            sameLocationSuppliersList: this.showSameLocationSuppliersList
              ? this.sameLocationSuppliersList
              : '',
            latestSuppliersList: this.showLatestSuppliersList
              ? this.latestSuppliersList
              : '',
            sex: this.sex,
            confirmationBy: this.confirmationBy,
            booker: this.booker,
            ownerRealName: this.ownerRealName,
            dateTimes: this.dateTimes,
            department: {
              departmentId: this.department,
              departmentsList: this.departmentsList
            },
            attenders: {
              attenderIds: this.attenders,
              attendersList: this.attendersList
            },
            bookingRef: this.bookingRef,
            paymentBookingRef: this.paymentBookingRef,
            caseNumber: this.caseNumber,
            clientName: this.showClientSection ? this.clientName : undefined,
            clientId: this.showClientSection ? this.clientId : undefined,
            paymentMethod: {
              paymentMethodId: this.showPaymentMethod ? this.paymentMethod : undefined,
              paymentMethodsList: this.paymentMethods
            },
            subject: this.subject,
            category: {
              categoryId: this.category,
              categoriesList: this.categoriesList
            },
            extra: this.extraInfo,
            audience: this.accessibility || 'private',
            participantsEmails: this.participantEmails,
            participantsPhones: this.participantPhones,
            contactAttributes: this.assignmentType === 'in_person' ? {
              savedContactAddress: {
                id: this.contactAddressChosenID,
                savedContactAddressesList: this.savedContactAddresses
              },
              person: {
                name: this.contactPersonName,
                phone: this.contactPersonPhone
              },
              address: this.fullAddress,
              description: this.contactWayDescription
            } : {},
            confirmationByPhone: this.showConfirmationsSection ? this.confirmationByPhone : undefined,
            actualCreatedAt: this.showReplacementSection ? this.actualCreatedAt : undefined,
            bookingMechanism: this.showReplacementSection ? this.bookingMechanism : undefined,
            manualFees: this.showManualFeeSection ? this.manualFees : undefined,
            customPriceTemplates: {
              demanderInternalTemplate: this.showPricingTemplatesSection ? this.demanderInternalTemplate : undefined,
              demanderInternalPriceTemplatesList: this.demanderTemplates.internal,
              demanderExternalTemplate: this.showPricingTemplatesSection ? this.demanderExternalTemplate : undefined,
              demanderExternalPriceTemplatesList: this.demanderTemplates.external,
              supplierHonorarTemplate: this.showPricingTemplatesSection ? this.supplierHonorarTemplate : undefined,
              supplierHonorarPriceTemplatesList: this.supplierTemplates.honorar,
              supplierCompanyTemplate: this.showPricingTemplatesSection ? this.supplierCompanyTemplate : undefined,
              supplierCompanyPriceTemplatesList: this.supplierTemplates.company,
              supplierEmployeeTemplate: this.showPricingTemplatesSection ? this.supplierEmployeeTemplate : undefined,
              supplierEmployeePriceTemplatesList: this.supplierTemplates.employee,
            },
            processRequirement: {
              ...this.processRequirements,
              autoApprove: this.showAutoApproveSection ? this.autoApprove : undefined,
              standBy: this.showStandBySection ? this.standBy : undefined
            },
            blockedCities: this.blockedCities,
            blockedSuppliers: this.blockedSuppliers
          }
        };
      }
    },
    methods: {
      validatePage() {
        this.resetValidationResult();
        this.$store.commit('ErrorsStore/removeAllErrors');
        const adminInfoIsValid = this.validateAdminSection();
        const personalInfoIsValid = this.validatePersonalInfoSection();
        const invoiceInfoIsValid = this.validateInvoiceSection();
        const assignmentInfoIsValid = this.validateMainSecretaryAssignmentInfoSection()
          & this.validateSecondaryAssignmentInfoSection();
        return personalInfoIsValid & assignmentInfoIsValid & adminInfoIsValid & invoiceInfoIsValid;
      },
      sendInfoFunc() {
        return this.$store.dispatch('PostSecretaryStore/createJob');
      },
      getCopyJob() {
        const replacement = this.$route.query.replacement && this.$store.state.TTAuthStore.godmode;
        let promisesArr = '';
        promisesArr = [...promisesArr, this.$store.dispatch('PostInterpretationStore/getCopyJob', {copyJobId: this.$route.query.copy, replacement})];
        Promise.all(promisesArr).then(() => {
          this.postAssignmentProgress = false;
        }).catch(() => {
          this.postAssignmentProgress = false;
        });
      }
    },
    mounted() {
      if (this.$route.query.copy) {
        this.getCopyJob();
      }
    },
    beforeRouteLeave(to, from, next) {
      // only if user leaves posting pages
      const assignmentTypeTo = to.meta.assignmentType || '';
      const assignmentTypeFrom = from.meta.assignmentType || '';
      if (!assignmentTypeTo || !assignmentTypeFrom) {
        this.$store.dispatch('PostSecretaryStore/clearPersonalInfoAndInvoiceStores');
      }
      this.$store.dispatch('PostSecretaryStore/clearAssignmentInfoStores');
      next();
    }
  };
</script>
